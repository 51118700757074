<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-hand-paper"></i>&nbsp;&nbsp;Opt-Out List</h2>
            </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Opt-Out csv file list</h3>
                        </template>
                        <div>
                        <!--UPLOAD-->
                                <form enctype="multipart/form-data">
                                    <!--<h5>Drag & Drop your suppression List (file type should be .csv). Download <a href="#">example file</a></h5>-->
                                    <div class="dropbox">
                                    <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                                        accept=".csv" class="input-file">
                                        <p v-if="isInitial">
                                        Drag your csv file here to upload<br/>or click to browse<br/>
                                        <base-button type="info" round icon  @click="show_helpguide('optoutlist')">
                                            <i class="fas fa-question"></i>
                                        </base-button>
                                        </p>
                                        <p v-if="isSaving">
                                            Please Wait, your file is being processed ...
                                        </p>
                                        <p v-if="isSuccess">
                                            Process finished successfully, Thank you!
                                        </p>
                                        <p v-if="isFailed">
                                            Whoops that did not work, please check your file for errors and try again
                                        </p>
                                    </div>
                                </form>
                        </div>
                        <ul v-if="optoutProgress.length > 0" class="mt-2 mb-0 mx-0 p-0" style="list-style: none; max-height: 90px; overflow: auto;">
                            <li v-for="(progress, index) in optoutProgress" :key="index" class="text-white m-0 p-0">
                                <i class="mr-2" :class="{'el-icon-loading': progress.status === 'progress', 'el-icon-circle-check': progress.status === 'done', 'el-icon-eleme': progress.status === 'queue'}"></i> 
                                <span class="mr-2">{{ progress.filename }}</span> 
                                <span v-if="progress.status === 'done'">{{ progress.status }}</span>
                                <span v-else>{{ progress.percentage }}%</span>
                            </li>
                        </ul>
                        <template slot="footer">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                    <a class="mt-2 d-inline-block" @click="purgeOptoutList" style="cursor: pointer; color: #FF0000;"><i class="fas fa-trash"></i> Purge Existing Records</a>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="false">
                                    <p>Your current opt-out list : <a :href="optoutpath + '/tools%2Foptout%2Foptoutlist.csv'" target="_blank">download here</a></p>
                                </div>
                            </div>
                        </template>
                    </card>
                
        </div>

        <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
            <h4 slot="header" class="title title-up" v-html="modals.helpguideTitle"></h4>
            <p class="text-center" v-html="modals.helpguideTxt">
            </p>
        
            <template slot="footer">
            <div class="container text-center pb-4">
                <base-button  @click.native="modals.helpguide = false">Close</base-button>
            </div>
            </template>
        </modal>

    </div>
</template>

<script>
import { Modal } from 'src/components';
import axios from 'axios';
import Swal from 'sweetalert2';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
axios.defaults.baseURL = process.env.VUE_APP_DATASERVER_URL + '/api';

export default {
    components: {
        Modal,
    },

    data() {
        return {
            /** FOR OPT OUT */
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'optoutfile',
            /** FOR OPT OUT */

            modals: {
                helpguide: false,
                helpguideTitle:'',
                helpguideTxt: '',
            },

            optoutfileexist: false,
            optoutpath: process.env.VUE_APP_CDN,

            optoutProgress: [],
            optoutInterval: '',
        }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },

    beforeRouteLeave (to, from, next) {
        clearInterval(this.optoutInterval);
        next();
    },

    methods: {
        purgeOptoutList() {
            Swal.fire({
                title: 'Record Purge Confirmation',
                text: "Are you sure you want to purge existing records?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                        confirmButton: 'btn btn-default btn-fill',
                        cancelButton: 'btn btn-danger btn-fill'
                    },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    // Perform the deletion
                    this.$store.dispatch('purgeOptoutList', {
                        companyRootId: this.$store.getters.userData.company_root_id
                    })
                    .then(response => {
                        // console.log(response);
                        if(response.data.result === 'success') {
                            Swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.msg,
                                confirmButtonText: 'OK'
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.msg,
                                confirmButtonText: 'OK'
                            });
                        }
                    })
                    .catch(error => {
                        // console.error(error);
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'There was an error processing your request.',
                            confirmButtonText: 'OK'
                        });
                    });
                }
            });
        },
        checkStatusFileUpload() {
            clearInterval(this.optoutInterval);

            /** START CHECK IF THERE IS ANYTHING NOT DONE */
            this.optoutInterval = setInterval(() => {

                this.$store.dispatch('jobProgress', {
                    companyId: this.$store.getters.userData.company_root_id
                })
                .then(response => {
                    // console.log(response);
                    this.optoutProgress = response.data.jobProgress;
                    
                    if(response.data.jobProgress[0]['status'] == 'done') {
                        clearInterval(this.optoutInterval);
                    }

                })
                .catch(error => {
                    console.error(error);
                })

            }, 2000);
            /** START CHECK IF THERE IS ANYTHING NOT DONE */
        },
        checkfileexist() {
            axios.get('/tools/dataenrichment/checkfile/root/optoutlist/optout')
            .then(response => {
                 if(response.data.result == 'success') {
                    this.optoutfileexist = true;
                }else{
                    this.optoutfileexist = false;
                }
            })
            .catch(error => {
            });
        },
        /** FOR POP UP GUIDE */
        show_helpguide(helptype) {
            if (helptype == 'optoutlist') {
                this.modals.helpguideTitle = "Opt Out List CSV"
                this.modals.helpguideTxt = "You can remove customer data on system database based on customer request, we can opt out by upload the list of the email seperate by new line. <a href='/samplefile/optoutlist.csv' target='_blank'>Click here</a> to download a Sample File"
            }

            this.modals.helpguide = true;
        },
         /** FOR POP UP GUIDE */

        /** FOR UPLOAD FILE */
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
            this.uploadFieldName = 'optoutfile';
            $('input[type="file"]').val(null);
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

           //console.log(formData);

           const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin' : '*',
                }
           };
           
            axios.post(process.env.VUE_APP_APISERVER_URL + '/api/tools/optout/upload',formData,config)
                .then(response => {
                    console.log(response.data);
                    if(response.data.result == 'success') {
                        this.currentStatus = STATUS_SUCCESS;
                        setTimeout(() => {
                            this.reset();
                        }, 2000);
                        this.checkfileexist();
                    }else{
                        this.currentStatus = STATUS_FAILED;
                        setTimeout(() => {
                            this.reset();
                        }, 3000);
                    }
                })
                .catch(error => {
                    // console.log(error);
                    this.currentStatus = STATUS_FAILED;
                    setTimeout(() => {
                        this.reset();
                    }, 3000);
                    //reject(error)
                })
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append(fieldName, fileList[x], fileList[x].name);
            });

            formData.append("companyRootId", this.$store.getters.userData.company_root_id);
        
            // save it
            this.save(formData);
        },
        /** FOR UPLOAD FILE */
    },

    mounted() {
            this.reset();
            this.checkfileexist();
            this.checkStatusFileUpload();
        }
}
</script>


<style>
.select-fullwidth {
    width: 100%;
}

.cardwithminimum {
    min-height: 275px;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    /* background: #27293d; */
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    /* background: #27293d; when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  
  .disabled-area {
    pointer-events:none;
    opacity: 0.3;
  }

  .selectcampaign {
    font-weight:bold;
    color:white;
  }
</style>